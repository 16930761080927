<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-toolbar color="indigo darken-3" dark dense flat>
            <v-toolbar-title>
              <v-icon>
                mdi-cctv
              </v-icon>
              รายการกล้อง CCTV
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select
              :items="types"
              label="ค้นหาจาก"
              style="width:40px; !important"
              v-model="type"
              hide-details
              single-line
              dense
              solo-inverted
            ></v-select>
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              width="200px"
              single-line
              hide-details
              dense
              solo-inverted
              label="กรอกคำค้นหา"
              clearable
              v-model="keyword"
              @keypress.enter="getList"
            ></v-text-field>
          </v-toolbar>
          <v-card-text>
            <v-spacer></v-spacer>
            <v-btn color="indigo darken-2" dark class="mr-1" @click="newOfficer">
              <v-icon small>
                mdi-plus-box
              </v-icon>
              เพิ่มกล้อง
            </v-btn>
          </v-card-text>
          <officer-table
            :keyword="keyword"
            :type="type"
            ref="otable"
            :item-selected.sync="itemSelected"
            @row-dbl-click="editOfficer"
            @remove-officer="deleteOfficer"
          ></officer-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- dialog -->
    <officer-dialog ref="odlg" v-model="officerDialog" :type="modify" @inserted="onInserted"></officer-dialog>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </v-container>
</template>

<script>
import officerTable from './components/officerTable'
import officerDialog from './components/officerDialog'
import { destroy } from '@/api/camera'
export default {
  methods: {
    getList() {
      this.$refs.otable.getList()
    },
    newOfficer() {
      this.$refs.odlg.init(null, 'add')
      this.officerDialog = true
    },
    editOfficer(id) {
      this.$refs.odlg.init(id, 'edit')
      this.officerDialog = true
    },

    async deleteOfficer(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            // console.log(res)
            if (res.success) {
              this.$toast.success({
                title: 'info',
                message: 'ทำรายการเรียบร้อยแล้ว',
              })
              this.$refs.otable.getList()
            }
          })
          .catch(err => {
            this.$toast.error({
              message: 'ไม่สามารถทำรายการได้',
              title: 'Error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onInserted() {
      this.officerDialog = false
      this.$refs.otable.getList()
    },

    cancel() {
      this.edit = false
      this.itemSelected = []
    },
  },
  data() {
    return {
      keyword: '',
      officerDialog: false,
      type: 'name',
      types: [{ text: 'ชื่อกล้อง', value: 'name' }],
      modify: 'add',
      loading: false,
      itemSelected: [],
      edit: false,
    }
  },
  components: {
    officerTable,
    officerDialog,
  },
  watch: {},
  created() {
    // this.getGroups()
  },
}
</script>

<style></style>
